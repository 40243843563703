import React from 'react';
import { useState,useEffect } from 'react';
 
import Grid from '@mui/material/Grid';
import typography from "./assets/theme/base/typography";
 
import ReactGA from 'react-ga4';
 
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
//import Card from "@material-ui/core/Card";

import Container from "@mui/material/Container";
import Button from '@mui/material/Button';
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
 
import Information from "Sections/Information";
import Team from "Sections/Team";
import Testimonial from "Sections/Testimonial";
import TBD from './TBD';
import Why from './Pages/Why';
import Contact from './Pages/ContactUs';
import Patient from './Pages/Patient';
import JoinWait from './Pages/JoinWaitlist';
 
 
import babyhandnew from 'Images/BabyHand-new.png';
 


 
import Collapse from "@mui/material/Collapse";
import breakpoints from "assets/theme/base/breakpoints";
 

export default function Home() {

    const [activeViewName, setActiveViewName] = useState('home');
    const url = 'https://orpleclientapp.azurewebsites.net/'
    const transparent = true;
    const light = true;
    const action = false;
    const sticky = false;
    const relative = false;
    const center = false;
    const [mobileNavbar, setMobileNavbar] = useState(false);
    const [mobileView, setMobileView] = useState(false);
    const openMobileNavbar = () => setMobileNavbar(!mobileNavbar);
   
    const { size } = typography;

     
    async function handleViewButtonClick(selectedTabName, e) {
        const viewName = selectedTabName;

        setActiveViewName(viewName);

        ReactGA.event({
            category: 'User',
            action: 'Clicked a button :' + viewName
        });

        //if (viewName == 'trybeta')
        //    window.open(url, '_blank');
    }

    useEffect(() => {
        // A function that sets the display state for the DefaultNavbarMobile.
        function displayMobileNavbar() {
            if (window.innerWidth < breakpoints.values.lg) {
                setMobileView(true);
                setMobileNavbar(false);
            } else {
                setMobileView(false);
                setMobileNavbar(false);
            }
        }

        /** 
         The event listener that's calling the displayMobileNavbar function when 
         resizing the window.
        */
        window.addEventListener("resize", displayMobileNavbar);

        // Call the displayMobileNavbar function to set the state with the initial value.
        displayMobileNavbar();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", displayMobileNavbar);
    }, []);

    return (

        <>
       


       


            <Container sx={sticky ? { position: "sticky", top: 0, zIndex: 10 } : null}>
                <MKBox
                    py={1}
                    px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }}
                    my={relative ? 0 : 2}
                    mx={relative ? 0 : 3}
                    width={relative ? "100%" : "calc(100% - 48px)"}
                    borderRadius="xl"
                    shadow={transparent ? "none" : "md"}
                    color={light ? "white" : "dark"}
                    position={relative ? "relative" : "absolute"}
                    left={0}
                    zIndex={3}
                    sx={({ palette: { transparent: transparentColor, white }, functions: { rgba } }) => ({
                        backgroundColor: transparent ? transparentColor.main : rgba(white.main, 0.8),
                        backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
                    })}
                >
                    <MKBox display="flex" justifyContent="space-between" alignItems="center">
                        <MKBox
                            //   component={Link}
                            // to="/"
                            lineHeight={1}
                            py={transparent ? 1.5 : 0.75}
                            pl={relative || transparent ? 0 : { xs: 0, lg: 1 }}
                            sx={{ cursor: "pointer", userSelect: "none" }}
                        >
                            <MKTypography variant="button" fontWeight="bold" color={light ? "#1d3060" : "dark"} onClick={(e) => handleViewButtonClick('home', e)}>
                                OrpleHealth
                            </MKTypography>
                        </MKBox>
                   
                        <MKBox
                            color="inherit"
                            display={{ xs: "none", lg: "flex" }}
                            ml="auto"
                            mr={center ? "auto" : 0}
                        >
                            {/*  {renderNavbarItems}*/}
                            <MKBox
                                // {...rest}
                                mx={1}
                                p={1}
                                display="flex"
                                alignItems="baseline"
                                color={light ? "#1d3060" : "dark"}
                                opacity={light ? 1 : 0.6}
                                sx={{ cursor: "pointer", userSelect: "none" }}
                            //{...(route && routeComponent)}
                            //   {...(href && linkComponent)}
                            >

                                <MKTypography
                                    variant="button"
                                    fontWeight="regular"
                                    textTransform="none"
                                    color={light ? "#1d3060" : "dark"}
                                    sx={{ fontWeight: "100%", ml: 1, mr: 0.25 }}
                                    onClick={(e) => handleViewButtonClick('patient', e)}
                                >
                                    Patients
                                </MKTypography>

                                {/*<MKButton color="default" sx={{ color: ({ palette: { dark } }) => dark.main }} onClick={(e) => handleViewButtonClick('trybeta', e)}>*/}
                                {/*    Try Beta*/}
                                {/*</MKButton>*/}
                            </MKBox>

                            <MKBox
                                //   {...rest}
                                mx={1}
                                p={1}
                                display="flex"
                                alignItems="baseline"
                                color={light ? "#1d3060" : "dark"}
                                opacity={light ? 1 : 0.6}
                                sx={{ cursor: "pointer", userSelect: "none" }}
                            //{...(route && routeComponent)}
                            // {...(href && linkComponent)}
                            >

                                <MKTypography
                                    variant="button"
                                    fontWeight="regular"
                                    textTransform="none"
                                    color={light ? "#1d3060" : "dark"}
                                    sx={{ fontWeight: "100%", ml: 1, mr: 0.25 }}
                                    onClick={(e) => handleViewButtonClick('why', e)}
                                >
                                    Why OrpleHealth
                                </MKTypography>
                            </MKBox>


                        </MKBox>
           
                        <MKBox
                           display={{ xs: "inline-block", lg: "none" }}
                            //display={{ xs: "none", lg: "flex" }}
                            //ml="auto"
                           // mr={center ? "auto" : 0}

                            lineHeight={1}
                            py={1.5}
                            pl={1.5}
                            color={transparent ? "#1d3060" : "inherit"}
                            sx={{ cursor: "pointer"}}
                            onClick={openMobileNavbar}
                        >
                            
                            {/*<Icon sx={{ ml: -3 }} fontSize="default">{mobileNavbar ? "X" : "&equiv;"}</Icon>*/}

                            {mobileNavbar &&
                                // <Icon sx={{ ml: -3 }} fontSize="default">{"X"}</Icon>
                                <CloseIcon sx={{ ml: -4 }} fontSize="default"></CloseIcon>   
                            }
                            {!mobileNavbar &&
                                <MenuIcon sx={{ ml: -4 }} fontSize="default"></MenuIcon>   
                            }
                        </MKBox>
                    </MKBox>
                    <MKBox
                        bgColor={transparent ? "white" : "transparent"}
                        shadow={transparent ? "lg" : "none"}
                        borderRadius="xl"
                        px={transparent ? 2 : 0}
                    >
                        {mobileView && 
                            <MKBox
                                bgcolor={transparent ? "white" : "transparent"}
                                shadow={transparent ? "lg" : "none"}
                                borderradius="xl"
                                px={transparent ? 2 : 0}
                            >
                                <Collapse in={Boolean(mobileNavbar)} timeout="auto" unmountOnExit>
                                    <MKBox width="calc(100% + 1.625rem)" my={2} ml={-2}>
                                        <MKBox
                                            // {...rest}
                                            mx={1}
                                            p={1}
                                            display="flex"
                                            alignItems="baseline"
                                            color={"dark"}
                                            opacity={light ? 1 : 0.6}
                                            sx={{ cursor: "pointer", userSelect: "none" }}
                                        //{...(route && routeComponent)}
                                        //   {...(href && linkComponent)}
                                        >

                                            <MKTypography
                                                variant="button"
                                                fontWeight="regular"
                                                textTransform="none"
                                               // color={light ? "white" : "dark"}
                                                color={"dark"}
                                                sx={{ fontWeight: "100%", ml: 1, mr: 0.25 }}
                                                onClick={(e) => handleViewButtonClick('patient', e)}
                                            >
                                                Patients
                                            </MKTypography>

                                            {/*<MKButton color="default" sx={{ color: ({ palette: { dark } }) => dark.main }} onClick={(e) => handleViewButtonClick('trybeta', e)}>*/}
                                            {/*    Try Beta*/}
                                            {/*</MKButton>*/}
                                        </MKBox>

                                        <MKBox
                                            // {...rest}
                                            mx={1}
                                            p={1}
                                            display="flex"
                                            alignItems="baseline"
                                            color={"dark"}
                                            opacity={light ? 1 : 0.6}
                                            sx={{ cursor: "pointer", userSelect: "none" }}
                                        //{...(route && routeComponent)}
                                        //   {...(href && linkComponent)}
                                        >

                                            <MKTypography
                                                variant="button"
                                                fontWeight="regular"
                                                textTransform="none"
                                                color={ "dark"}
                                                sx={{ fontWeight: "100%", ml: 1, mr: 0.25 }}
                                                onClick={(e) => handleViewButtonClick('why', e)}
                                            >
                                                Why
                                            </MKTypography>

                                            {/*<MKButton color="default" sx={{ color: ({ palette: { dark } }) => dark.main }} onClick={(e) => handleViewButtonClick('trybeta', e)}>*/}
                                            {/*    Try Beta*/}
                                            {/*</MKButton>*/}
                                        </MKBox>


                                        {/*<MKBox*/}
                                        {/*    // {...rest}*/}
                                        {/*    mx={1}*/}
                                        {/*    p={1}*/}
                                        {/*    display="flex"*/}
                                        {/*    alignItems="baseline"*/}
                                        {/*    color={"dark"}*/}
                                        {/*    opacity={light ? 1 : 0.6}*/}
                                        {/*    sx={{ cursor: "pointer", userSelect: "none" }}*/}
                                        {/*//{...(route && routeComponent)}*/}
                                        {/*//   {...(href && linkComponent)}*/}
                                        {/*>*/}
                                        {/*    <MKTypography*/}
                                        {/*        variant="button"*/}
                                        {/*        fontWeight="regular"*/}
                                        {/*        textTransform="none"*/}
                                        {/*        color={"dark"}*/}
                                        {/*        sx={{ fontWeight: "100%", ml: 1, mr: 0.25 }}*/}
                                        {/*        onClick={(e) => handleViewButtonClick('trybeta', e)}*/}
                                        {/*    >*/}
                                        {/*        Try Beta*/}
                                        {/*    </MKTypography>*/}

                                           

                                          
                                        {/*    */}{/*<MKButton color="default" sx={{ color: ({ palette: { dark } }) => dark.main }} onClick={(e) => handleViewButtonClick('trybeta', e)}>*/}
                                        {/*    */}{/*    Try Beta*/}
                                        {/*    */}{/*</MKButton>*/}
                                        {/*</MKBox>*/}


                                        <MKBox
                                            // {...rest}
                                            mx={1}
                                            p={1}
                                            display="flex"
                                            alignItems="baseline"
                                            color={"dark"}
                                            opacity={light ? 1 : 0.6}
                                            sx={{ cursor: "pointer", userSelect: "none" }}
                                        //{...(route && routeComponent)}
                                        //   {...(href && linkComponent)}
                                        >
                                            <MKTypography
                                                variant="button"
                                                fontWeight="regular"
                                                textTransform="none"
                                                color={"dark"}
                                                sx={{ fontWeight: "100%", ml: 1, mr: 0.25 }}
                                                onClick={(e) => handleViewButtonClick('betawait', e)}
                                            >
                                               Get a demo
                                            </MKTypography>




                                          
                                        </MKBox>



                                    </MKBox>
                                </Collapse>
                            </MKBox>
                        
                        }
                    </MKBox>
                </MKBox>
                {/*{dropdownMenu}*/}
                {/*{nestedDropdownMenu}*/}
            </Container>

            {(activeViewName == 'home') &&
                <>
                    < MKBox
                        minHeight="75vh"
                        width="100%"
                        sx={{
                            //backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                            //    `${linearGradient(
                            //        rgba(gradients.dark.main, 0.6),
                            //        rgba(gradients.dark.state, 0.6)
                            //    )}, url(${websitenew})`,
                            bgcolor: "#E6E6E6",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            display: "grid",
                            placeItems: "center",
                        }}
                    >
                        <Container>
                            <Grid
                                container
                                item
                                xs={12}
                                lg={12}
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                                sx={{ mx: "auto", textAlign: "center" }}
                            >
                                <MKTypography
                                    variant="h1"
                                color="#1d3060"
                                    sx={({ breakpoints, typography: { size } }) => ({
                                        [breakpoints.down("md")]: {
                                            fontSize: size["3xl"],
                                        },
                                    })}
                                >
                                {/*All your health records in one place*/}
                               Your complete medical history.
                            </MKTypography>

                            {/*<MKTypography*/}
                            {/*    variant="h1"*/}
                            {/*    color="white"*/}
                            {/*    sx={({ breakpoints, typography: { size } }) => ({*/}
                            {/*        [breakpoints.down("md")]: {*/}
                            {/*            fontSize: size["3xl"],*/}
                            {/*        },*/}
                            {/*    })}*/}
                            {/*>*/}
                            {/*    Own your health data*/}
                            {/*</MKTypography>*/}
                            {/*    <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>*/}
                            {/*    */}{/*    <b>Our Mission:</b> Empower each individual with easy access to all of their health records and contribute to the progress of clinical science.*/}
                            {/*    Understand and take control of your health.*/}
                            {/*</MKTypography>*/}

                            <MKTypography variant="body1" color="#1d3060" opacity={0.8} mt={2} mb={3} sx={{ fontFamily: 'Maisonneuemono, sans-serif', }}>
                                Understand and take control of your health.
                            </MKTypography>

                                {/*<MKButton color="default" sx={{ color: ({ palette: { dark } }) => dark.main }} onClick={(e) => handleViewButtonClick('trybeta', e)}>*/}
                                {/*Try Beta*/}

                            {/*</MKButton>*/}


                            {/*<MKButton color="default" sx={{ color: ({ palette: { dark } }) => dark.main }} onClick={(e) => handleViewButtonClick('betawait', e)}>*/}
                            {/*    Join Beta Waitlist*/}
                            {/*</MKButton>*/}

                            <Button variant="contained" sx={{
                                //mt: 3, ml: 3, mr: 3, mb: 1,
                                //m: 3,
                                height: '45px',
                                fontSize: 16,
                                bgcolor: '#1d3060', color: '#ffff',
                                textTransform: 'capitalize'
                            }}
                                onClick={(e) => handleViewButtonClick('betawait', e)}
                            >Get a demo</Button>


                                <MKTypography variant="h6" color="white" mt={8} mb={1}>

                                </MKTypography>

                            </Grid>
                        </Container>
                    </MKBox>

                    <Card
                        sx={{
                           // p: 2,
                            mx: { xs: 2, lg: 3 },
                            mt: -8,
                            mb: 4,
                            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                            backdropFilter: "saturate(200%) blur(30px)",
                            boxShadow: ({ boxShadows: { xxl } }) => xxl,
                        }}
                    >

                        {/*<MKBox component="section" py={12}>*/}
                        {/*    <Container>*/}
                        {/*        <Grid container spacing={3} alignItems="center">*/}
                        {/*            <Grid item xs={12} lg={6}>*/}
                        {/*                <Grid container justifyContent="flex-start">*/}
                        {/*                    <Grid item xs={12} md={6}>*/}
                        {/*                        <MKBox mb={5}>*/}
                        {/*                            <DefaultInfoCard*/}
                        {/*                                //icon="public"*/}
                        {/*                                title="Patient centric"*/}
                        {/*                                description1="Our patient centric platform enables individual patients to bring in longitudinal health data from various sources. Propriety data engine creates unified health records for each individual patient."*/}
                        {/*                                description2="OrpleHealth platform generates secure, compliant, and always available population health data pool. Get valuable insights from your own health records."*/}
                        {/*                            />*/}
                        {/*                        </MKBox>*/}
                        {/*                    </Grid>*/}

                        {/*                </Grid>*/}
                        {/*            </Grid>*/}
                        {/*            <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>*/}
                        {/*                <CenteredBlogCard*/}
                        {/*                    image={unlockImg}*/}
                        {/*                />*/}
                        {/*            </Grid>*/}
                        {/*        </Grid>*/}
                        {/*    </Container>*/}
                        {/*</MKBox>*/}

                        {/*<Newsletter*/}
                        {/*    title="Patient centric"*/}
                        {/*    description1="Our patient centric platform enables individual patients to bring in longitudinal health data from various sources. Propriety data engine creates unified health records for each individual patient.OrpleHealth platform generates secure, compliant, and always available population health data pool. Get valuable insights from your own health records."*/}
                        {/*    />*/}

                        {/*<Newsletter*/}
                        {/*    title="Advance medical research"*/}
                        {/*    description1="Anonymized health records shared  after obtaining  patients' consent contributes to advanced medical research. Research partners collaborate with us to derive true value out of health data and take the clinical research to the next level."*/}
                        {/*/>*/}
                        {/*<Newsletter*/}
                        {/*    title="Developer community"*/}
                        {/*    description1="Shared health records are  available via API in FHIR format for third party development apps to consume and harness the power of rich data set."*/}
                        {/*/>*/}

                        <Information />
                        <Team />
                        <Testimonial />
                    </Card>
                </>
            }
            {(activeViewName == 'why') &&
                <Why></Why>
            }
            {(activeViewName == 'patient') &&
                <Patient></Patient>
            }
            {(activeViewName == 'betawait') &&
                <JoinWait></JoinWait>
            }
            {(activeViewName == 'contact') &&
                <Contact></Contact>
            }


            <MKBox pt={6} pb={6} px={1} mt={3} >
                {/*<SimpleFooter light />*/}
                <Container>
                    <MKBox
                        width="100%"
                        display="flex"
                        flexDirection={{ xs: "column", lg: "row" }}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <MKBox
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexWrap="wrap"
                            //  color={light ? "white" : "text"}
                            color="dark"
                            fontSize={size.sm}
                        >
                           Copyright  &copy;
                            {new Date().getFullYear()} Orple LLC. All rights reserved.
                      
                        </MKBox>
                    
                        <MKBox
                            component="ul"

                            sx={({ breakpoints }) => ({
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "center",
                                listStyle: "none",
                                mt: 3,
                                mb: 0,
                                p: 0,

                                [breakpoints.up("lg")]: {
                                    mt: 0,
                                },
                            })}
                        >
                            <MKBox
                               // key={link.name}
                                component="li"
                                //pl={key === 0 ? 0 : 2}
                                pl={2}
                               // pr={key === links.length - 1 ? 0 : 2}
                                pr={2}
                                lineHeight={1}

                            >
                              
                                    <MKTypography variant="button" fontWeight="regular"
                                        //color={light ? "white" : "text"}
                                        color="dark"
                                    >
                                        Orple LLC.
                                    </MKTypography>
                                 
                            </MKBox>
                            <MKBox
                                //key={link.name}
                                component="li"
                                // pl={key === 0 ? 0 : 2}
                                pl={2}
                               // pr={key === links.length - 1 ? 0 : 2}
                                pr={2 }
                                lineHeight={1}
                                sx={{ cursor: "pointer", userSelect: "none" }}
                            >
                               
                                    <MKTypography variant="button" fontWeight="regular"
                                        //color={light ? "white" : "text"}
                                    color="dark"
                                    onClick={(e) => handleViewButtonClick('home', e)}
                                    >
                                        About Us
                                    </MKTypography>
                                
                            </MKBox>

                            <MKBox
                               // key={link.name}
                                component="li"
                                //pl={key === 0 ? 0 : 2}
                                pl={2}
                               // pr={key === links.length - 1 ? 0 : 2}
                                pr={2 }
                                lineHeight={1}
                                sx={{ cursor: "pointer", userSelect: "none" }}
                            >
                               
                                    <MKTypography variant="button" fontWeight="regular"
                                        //color={light ? "white" : "text"}
                                    color="dark"
                                    onClick={(e) => handleViewButtonClick('contact', e)}
                                    >
                                        Contact Us
                                    </MKTypography>
                                 
                            </MKBox>

                        </MKBox>
                    </MKBox>
                </Container>
            </MKBox>

        </>
    );
}