/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "../Examples/Cards/HorizontalTeamCard";

// Images
import team1 from "assets/images/team-5.jpg";
import team2 from "assets/images/bruce-mars.jpg";
import team3 from "assets/images/ivana-squares.jpg";
import team4 from "assets/images/ivana-square.jpg";

import cityprofile from '../Images/blog2.jpg';
import whatdo from '../Images/what-do-4.png';

function Team() {
    return (
        <MKBox
            component="section"
           // variant="gradient"
           bgColor="dark"
            //bgcolor= "#E6E6E6"
            position="relative"
            py={6}
            px={{ xs: 2, lg: 0 }}
            mx={-2}
        >
            <Container>
                <Grid container >
                    <Grid item xs={12} md={8} sx={{ mb: 6 }}>
                        <MKTypography variant="h3" color="white">
                            What do we do?
                        </MKTypography>
                        <MKTypography variant="body2" color="white" opacity={0.8}>
                            Our platform developed with advanced technologies including proprietary data engine, cloud and AI,  enables you to bring in your health records from a variety of sources : EHR(s), scanned paper documents, radiology CD/DVD and wearable devices.
                        </MKTypography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}
                        //lg={6}
                       
                    >
                        <MKBox mb={1}>
                            <HorizontalTeamCard
                              //  image={cityprofile}
                                image={whatdo}
                                name="Emma Roberts"
                                position={{ color: "info", label: "UI Designer" }}
                                description="Our platform developed with advanced technologies including proprietary data engine, cloud and AI,  enables you to bring in your health records from a variety of sources : EHR(s), paper documents, radiology cd/DVD and wearable devices."
                            />
                        </MKBox>
                    </Grid>
                    {/*<Grid item xs={12} lg={6}>*/}
                    {/*    <MKBox mb={1}>*/}
                    {/*        <HorizontalTeamCard*/}
                    {/*            image={team2}*/}
                    {/*            name="William Pearce"*/}
                    {/*            position={{ color: "info", label: "Boss" }}*/}
                    {/*            description="Artist is a term applied to a person who engages in an activity deemed to be an art."*/}
                    {/*        />*/}
                    {/*    </MKBox>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12} lg={6}>*/}
                    {/*    <MKBox mb={{ xs: 1, lg: 0 }}>*/}
                    {/*        <HorizontalTeamCard*/}
                    {/*            image={team3}*/}
                    {/*            name="Ivana Flow"*/}
                    {/*            position={{ color: "info", label: "Athlete" }}*/}
                    {/*            description="Artist is a term applied to a person who engages in an activity deemed to be an art."*/}
                    {/*        />*/}
                    {/*    </MKBox>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12} lg={6}>*/}
                    {/*    <MKBox mb={{ xs: 1, lg: 0 }}>*/}
                    {/*        <HorizontalTeamCard*/}
                    {/*            image={team4}*/}
                    {/*            name="Marquez Garcia"*/}
                    {/*            position={{ color: "info", label: "JS Developer" }}*/}
                    {/*            description="Artist is a term applied to a person who engages in an activity deemed to be an art."*/}
                    {/*        />*/}
                    {/*    </MKBox>*/}
                    {/*</Grid>*/}
                </Grid>
            </Container>
        </MKBox>
    );
}

export default Team;
