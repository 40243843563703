 
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

 
import MKBox from "components/MKBox";

import DefaultInfoCard from "../Examples/Cards/DefaultInfoCard";
import CenteredBlogCard from "../Examples/Cards/CenterBlogCard";
import BackgroundBlogCard from "../Examples/Cards/BackgroundBlogCard";
import unlockImg from '../Images/unlock.jpg';
import bg9 from '../Images/blog9.jpg';
 
import familylook from 'Images/family-look.jpg';
import websitenew from 'Images/websitenew.png';
import MKTypography from "components/MKTypography";
function Information() {
    return (
        <MKBox component="section"
            py={12}
            //sx={{p:18,} }
            
        >
            <Container 
              

            >

                <Grid container spacing={5} alignItems="center" 
              //  sx={{ m: 5 }} //use this for spacing from parent
                >

                    <Grid item
                        xs={12}
                        lg={6}//to remove the sreen partition into 2 columns, comment this break statment 12/2/24
                    //xs={4} 

                    >
                        <Grid container
                            justifyContent="flex-start"
                        >
                            <Grid item xs={12}
                            //    md={6}//revert back if alignement does not work. will educe  the column width
                            >
                                <MKBox
                                // mb={5}
                                >
                                    <DefaultInfoCard
                                        //icon="public"
                                        title="Universal health record"
                                        description="Our patient centric platform enables individual patients to bring in longitudinal health data from various sources. Propriety data engine creates unified health records for each individual patient."
                                    />
                                </MKBox>
                            </Grid>
                            <Grid item xs={12}
                            //md={6}
                            >
                                {/*<MKBox*/}
                                {/*    //mb={5}*/}
                                {/*>*/}
                                {/*    <DefaultInfoCard*/}
                                {/*     //   icon="payments"*/}
                                {/*        title="Get Insights"*/}
                                {/*        description="OrpleHealth platform generates secure, compliant, and always available population health data pool. Get valuable insights from your own health records."*/}
                                {/*    />*/}
                                {/*</MKBox>*/}
                            </Grid>
                            <Grid item xs={12}
                            //md={6}
                            >
                                {/*<MKBox mb={{ xs: 5, md: 0 }}>*/}
                                {/*    <DefaultInfoCard*/}
                                {/*        //     icon="apps"*/}
                                {/*        title="Advanced Research"*/}
                                {/*        description="Anonymized health records shared  after obtaining  patients' consent contributes to advanced medical research.Research partners collaborate with us to derive true value out of health data and take the clinical research to the next level."*/}
                                {/*    />*/}
                                {/*</MKBox>*/}
                                <MKBox mb={{ xs: 5, md: 0 }}>
                                    <DefaultInfoCard
                                        //     icon="apps"
                                        title="Simplify evidence based healthcare"
                                        description="Access your records anytime from anywhere. Easily share health details with your provider and receive accurate care."
                                    />
                                </MKBox>

                            </Grid>

                            <Grid item xs={12}
                                //sx={{ mt: 8, mb: 8 }}
                            // md={6}
                            >
                                {/*<MKBox mb={{ xs: 5, md: 0 }}>*/}
                                {/*    <DefaultInfoCard*/}
                                {/*        //     icon="3p"*/}
                                {/*        title="Developer Community"*/}
                                {/*        description="Shared health records are  available via API in FHIR format for third party development apps to consume and harnessthe power of rich data set."*/}
                                {/*    />*/}
                                {/*</MKBox>*/}

                             
                                <MKBox mb={{ xs: 5, md: 0 }}>
                                    <DefaultInfoCard
                                        //     icon="3p"
                                        title="Own your health history."
                                        description="Don't depend on the provider or lab to maintain parts of your health history. Take charge. Your health data is yours to keep. Period."
                                    />
                                </MKBox>


                                {/*<MKTypography*/}
                                {/*    variant="h3"*/}
                                {/*    color="#1d3060"*/}
                                {/*    sx={({ breakpoints, typography: { size } }) => ({*/}
                                {/*        [breakpoints.down("md")]: {*/}
                                {/*            fontSize: size["3xl"],*/}
                                {/*        },*/}
                                {/*    })}*/}
                                {/*>*/}
                                {/*    */}{/*All your health records in one place*/}
                                {/*    Your health data is yours to keep. Period.*/}
                                {/*</MKTypography>*/}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item
                        xs={12}
                        lg={6} //to remove the sreen partition into 2 columns, comment this break statment 12/2/24
                        //xs={8 }
                        sx={{
                            ml: "auto",
                            mt: { xs: 3, lg: 0 }
                        }}
                    >
                        <CenteredBlogCard
                            //image={familylook}
                            image={websitenew}
                        // image={bg9}
                        // image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
                        // title="Unlock the true potential of health data "
                        //description="OrpleHealth platform generates secure, compliant, and always available population health data pool. Get valuable insights from your own health records."

                        />



                    </Grid>

              
                    
               
                </Grid>
            </Container>
        </MKBox>
    );
}

export default Information;
